<template>
<div>
    
    <div>
        <div class="bg-gray-800">
            <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
            <div class="max-w-xl">
                <h2 class="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">My Warranty</h2>
                <p class="mt-5 text-xl text-gray-400">Search for your {{company}} warranty information by entering the reference code that was sent to your email.</p>
                <p class="text-gray-400" >If you did not receive your reference code please contact us</p>
            </div>
            <div class="mt-10 w-full max-w-xs">
                <label class="block text-base font-medium " :class="`${textbrand300}`">Warranty Reference</label>
                    <div class="mt-1.5 relative">
                        <form class="flex" @submit.prevent="handleSubmit">
                            <input v-model="warrantyRef" type="text" name="warrantyNumber" id="warrantyNumber" class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`"  placeholder="Enter your unique warranty reference" />
                            <button class="border rounded px-2 ml-2 text-gray-50" :class="`${bgbrand500}`">Search</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
   <div  v-if="warrantyRef" class="bg-white shadow overflow-hidden sm:rounded-lg p-5 m-2">

    <div v-for="warranty in documents" :key="warranty.id" class=" border-gray-200 px-4 sm:p-0">
        <div v-if="warranty.id === warrantyRef">
            <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Warranty Information
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Here is everything we collected about your install and warranty.
                </p>
            </div>
            <dl class="divide-y divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-bold text-gray-500">
                    Warranty Reference
                </dt>
                <dd class="mt-1 text-sm font-bold text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.id}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-bold text-gray-500">
                    Warranty Validity
                </dt>
                <dd class="mt-1 text-sm font-bold text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.warranty}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Full name
                </dt>
                <dd class="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-2">
                    Hidden
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Phone Number
                </dt>
                <dd class="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-2">
                    Hidden
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Email address
                </dt>
                <dd class="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-2">
                    Hidden
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Start Date
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.date}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Tint Brand
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.tintBrand}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Vehicle
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.vehicleName}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Vin Number
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.vinNumber}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Vehicle Type
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.vehicleType}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Install Type
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.installType}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Rear Shade Option
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.shadeOptionRear}}
                </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Front Shade Option
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{warranty.shadeOptionFront}}
                </dd>
                </div>

            </dl>
        </div>
        <div v-if="warranty.id !== warrantyRef" class="hidden"> </div>

    </div>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import { ref } from 'vue'
import configSetup from '@/setup.js'


export default {
    created() {
    const {company} = configSetup()
    document.title = `${company} | My Warranty`
},

    setup() {
        const {borderbrand500, company, textbrand300, bgbrand500,ringbrand500,dbWarranty} = configSetup()

        const {error, documents} = getCollection('companyProfile', 'tintWarranties')

        const warrantyRef = ref('')
        const warranty = ref(`documents.${warrantyRef.value}`)
        const inValid = ref(true)

        const handleSubmit = async () => {
            warrantyRef.value = await warrantyRef.value
            inValid.value = false
        }
        console.log(documents.value)
        
                    
        return {warrantyRef, warranty, documents, error, handleSubmit, inValid, borderbrand500, company, textbrand300, bgbrand500, ringbrand500}
    }
}
</script>